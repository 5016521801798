import Heading from 'components/ui/Heading';
import { BlockHeadingData } from 'lib/contentful/types';

type BlockHeadingProps = Omit<BlockHeadingData, '__typename'> & {
  __typename: string;
};

const BlockHeading: React.FC<BlockHeadingProps> = (props) => {
  const { subHeading, heading, divider } = props;
  return <Heading heading={heading} subHeading={subHeading} divider={divider} />;
};

export default BlockHeading;
