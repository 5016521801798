import Img from 'components/Img';
import Container from 'components/layout/Container';
import { BlockHeroData } from 'lib/contentful/types';
import { FillLinkButton } from 'components/Button';

type BlockHeroProps = Omit<BlockHeroData, '__typename'> & {
  __typename: string;
};

const BlockHero: React.FC<BlockHeroProps> = (props) => {
  const { image, heading, callToActionButton, badge } = props;

  return (
    <div>
      <div className="relative w-full pb-10">
        <div className="relative h-[70vh] w-full lg:h-screen lg:min-h-[800px]">
          <Img
            {...image}
            priority={true}
            layout={'fill'}
            objectFit="cover"
            className="object-[90%_center] lg:object-[45%_center]"
          />
        </div>
        <div className="inset-0 flex items-center justify-center lg:absolute">
          <Container className="pt-12">
            {badge && (
              <div className="absolute right-0 w-1/4 -translate-y-6 text-center sm:w-2/12 sm:translate-y-6 2xl:-right-24">
                <img
                  className="mx-auto -translate-y-full"
                  src="/assets/images/general/hero-badge.png"
                  alt=""
                />
              </div>
            )}
            <div className="absolute right-0 w-1/4 -translate-y-full sm:-top-32 sm:w-1/12 sm:translate-y-0"></div>
            <div className="grid grid-cols-4 gap-4 sm:grid-cols-12">
              <div className="col-span-4 sm:col-span-8 sm:col-start-2 lg:col-span-5 lg:col-start-2">
                <div className="flex flex-col items-start gap-8">
                  <h1 className="w-10/12 text-3xl uppercase leading-heading text-sf-blue-700 sm:w-full sm:text-6xl sm:leading-heading">
                    {heading}
                  </h1>
                  {callToActionButton && (
                    <FillLinkButton className="uppercase" {...callToActionButton}></FillLinkButton>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default BlockHero;
