import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { RecipeData } from 'lib/contentful/types';
import { recipeLink } from 'utils/links';
import ImageCard from './ImageCard';
import RecipeCardTitle from './RecipeCardTitle';

interface RecipeCardProps {
  recipe: RecipeData;
  index: number;
  timestamp: number;
}

const RecipeCard: React.FC<RecipeCardProps> = (props) => {
  const { recipe, index, timestamp } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    gsap.fromTo(
      container,
      { opacity: 0, scale: 1, y: 30, rotationY: 90, transformOrigin: 'center center' },
      {
        opacity: 1,
        scale: 1,
        y: 0,
        rotationY: 0,
        duration: 1,
        delay: index / 10,
        ease: 'power3.out',
      }
    );

    return () => {
      gsap.killTweensOf(container);
    };
  }, [index, timestamp]);

  return (
    <>
      <div className="gpu perspective flex">
        <div ref={containerRef} className="relative flex h-full w-full opacity-0">
          <ImageCard
            sizes="(max-width:767px) 90vw, 600px"
            href={recipeLink(recipe.slug)}
            image={recipe.image}
            type={'listing'}
          >
            <RecipeCardTitle recipe={recipe} />
          </ImageCard>
        </div>
      </div>
    </>
  );
};

export default RecipeCard;
