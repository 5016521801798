import React, { useEffect, useRef, useState } from 'react';
import Question from 'components/question/Question';
import Container from 'components/layout/Container';
import { BlockFaqsData } from 'lib/contentful/types';
import QuestionHeadline from 'components/question/QuestionHeadline';
import QuestionLink from 'components/question/QuestionLink';
import gsap from 'gsap';
import Select from 'components/ui/SelectFilter';

type BlockFaqsProps = Omit<BlockFaqsData, '__typename'> & {
  __typename: string;
};

const BlockFaqs: React.FC<BlockFaqsProps> = (props) => {
  const {
    sectionsCollection: { items },
  } = props;

  const currentItems = items.filter((x) => x); //draft items on contentful will be undefined - @todo handle this in data-layer/apollo client;

  const collectionBlockRefs = useRef([]);
  const scrollOffsetY = 122;

  const [activeIndex, setActiveIndex] = useState(0);

  const questionLinkHandler = (index: number) => {
    const section = collectionBlockRefs.current[index];

    gsap.to(window, {
      duration: 0.6,
      ease: 'power3.inOut',
      scrollTo: { y: section, autoKill: false, offsetY: scrollOffsetY },
    });
  };

  useEffect(() => {
    const blocks = collectionBlockRefs.current;
    if (!blocks) return;
    let requestAnimation = null;

    const animate = () => {
      const visibleBlocks = blocks.filter((block) => block.getBoundingClientRect().y > 0);

      const currentIndex = blocks.length - visibleBlocks.length;
      setActiveIndex(currentIndex);

      requestAnimation = window.requestAnimationFrame(animate);
    };

    requestAnimation = window.requestAnimationFrame(animate);

    return () => {
      window.cancelAnimationFrame(requestAnimation);
    };
  }, []);

  return (
    <Container>
      <div className="relative w-full py-10 text-sf-blue-700 md:flex md:flex-row md:items-start md:justify-center md:gap-x-10 md:py-16">
        <div className="relative w-auto md:sticky md:top-32 md:min-w-[212px] lg:min-w-[312px]">
          <div className="relative hidden w-full flex-col md:flex">
            {currentItems.map((collection, index) => {
              return (
                <div key={collection.sys?.id}>
                  <QuestionLink
                    icon={collection.icon}
                    title={collection.title}
                    active={activeIndex === index}
                    useBottomBar={true}
                    onClick={() => {
                      questionLinkHandler(index);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="relative w-full pb-16 md:hidden">
            <Select
              items={currentItems}
              onChange={(cuurrentIndex: number) => {
                questionLinkHandler(cuurrentIndex);
              }}
            />
          </div>
        </div>
        <div className="flex flex-1">
          <div className="relative w-full">
            {currentItems.map((collection, index) => {
              return (
                <div
                  ref={(element) => {
                    collectionBlockRefs.current[index] = element;
                  }}
                  key={collection.sys?.id}
                  className="no-select relative w-full pb-16"
                >
                  <QuestionHeadline index={index}>{collection.title}</QuestionHeadline>
                  <div>
                    {collection.questionsCollection?.items.map((question) => {
                      return (
                        <Question
                          key={question.sys?.id}
                          question={question.question}
                          answer={question.answer}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BlockFaqs;
