import { ProductData } from 'lib/bigcommerce/types';
import { LinkData } from 'lib/contentful/types';
import { useEffect, useRef, useState } from 'react';
import Container from 'components/layout/Container';
import CarouselSwiper from 'components/carousel/CarouselSwiper';
import Link from 'components/Link';
import classnames from 'utils/classnames';
import CarouselProductCard from 'components/card/CarouselProductCard';
import track from 'lib/tracking';
interface ProductCarouselProps {
  heading: string;
  cta: Partial<LinkData>;
  products: ProductData[];
  trackingUnique: string;
  trackingName: string;
  trackingId: string;
}

const ProductCarousel: React.FC<ProductCarouselProps> = (props) => {
  const { heading, cta } = props;

  const ref = useRef<string[]>([]);

  const [products, setProducts] = useState<Array<ProductData>>(props.products);

  const [filter, setFilter] = useState<'POPULAR' | 'SUBSCRIPTION'>('POPULAR');

  const trackUniqueInteractionsOnce = () => {
    if (!ref.current.includes(filter)) {
      ref.current.push(filter);
      track.viewList(
        `${props.trackingName} ${props.trackingUnique} ${filter.toLowerCase()}`,
        `${props.trackingName} ${filter.toLowerCase()}`,
        products.map((p) => {
          return { item_id: p.sku.toString(), item_name: p.name };
        })
      );
    }
  };

  useEffect(() => {
    const filteredProducts =
      filter === 'POPULAR' ? props.products : props.products.filter((x) => x.subscriptionEnabled);
    setProducts(filteredProducts);
  }, [filter]);

  return (
    <>
      <div className="relative w-full overflow-hidden text-sf-blue-700">
        <Container>
          <div className="relative w-full py-10 md:py-20">
            <div className="relative w-full pb-4">
              <div className="pb-2">
                <div className="relative flex w-full items-center justify-between">
                  <div>{heading && <h4 className="text-3xl uppercase">{heading}</h4>}</div>
                  {cta && (
                    <>
                      <div>
                        {cta && <Link {...cta} className="text-base font-medium uppercase" />}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-2 flex flex-nowrap gap-x-3 text-sf-blue-700">
                <button
                  onClick={() => setFilter('POPULAR')}
                  className={classnames('text-base font-medium uppercase', {
                    'opacity-30': filter !== 'POPULAR',
                  })}
                >
                  Popular
                </button>
                <button
                  onClick={() => setFilter('SUBSCRIPTION')}
                  className={classnames('text-base font-medium uppercase', {
                    'opacity-30': filter !== 'SUBSCRIPTION',
                  })}
                >
                  Subscription
                </button>
              </div>
            </div>
            <div onMouseDown={trackUniqueInteractionsOnce}>
              <CarouselSwiper length={products.length}>
                {products.map((product) => (
                  <CarouselProductCard key={product.entityId} product={product} />
                ))}
              </CarouselSwiper>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ProductCarousel;
