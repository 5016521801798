import Img from 'components/Img';
import React from 'react';
import classnames from 'utils/classnames';

interface QuestionLinkProps {
  icon: any;
  title: string;
  useBottomBar: boolean;
  active: boolean;
  onClick: () => void;
}

const QuestionLink: React.FC<QuestionLinkProps> = (props) => {
  const { title, icon, useBottomBar, active, onClick } = props;

  return (
    <button
      className={classnames('relative w-full py-4', {
        'opacity-20': !active,
      })}
      onClick={onClick}
    >
      <div className="reative flex items-center">
        {icon?.url ? (
          <Img width={22} height={16} url={icon?.url} layout="fixed" objectFit="cover" />
        ) : (
          <div className="h-8 w-8" />
        )}
        <div className={classnames('ml-5 text-base font-medium uppercase text-sf-blue-700')}>
          {title}
        </div>
      </div>
      {useBottomBar && (
        <>
          <div
            className={classnames(
              'absolute bottom-0 left-0 right-0 h-[2px] w-full bg-sf-blue-700',
              {
                'opacity-0': !active,
              }
            )}
          ></div>
        </>
      )}
    </button>
  );
};

export default QuestionLink;
