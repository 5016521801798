interface NoRecipesIconProps {
  className?: string;
}

const NoRecipesIcon: React.FC<NoRecipesIconProps> = (props) => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <circle cx="25.093" cy="23.9534" r="9" stroke="#172A4F" strokeWidth="2" />
        <circle cx="25.093" cy="23.9534" r="13" stroke="#172A4F" strokeWidth="2" />
        <path
          d="M44.485 37.0467L45.5849 17.0769C45.6456 15.974 44.7295 15.1748 43.9873 15.9928C42.775 17.3289 41.5286 20.283 41.1064 25.8412C41.0646 26.3919 41.488 26.8665 42.0395 26.8968V26.8968"
          stroke="#172A4F"
          strokeWidth="2"
        />
        <path d="M8.92246 34.8003L5.26343 13.1067" stroke="#172A4F" strokeWidth="2" />
        <path
          d="M7.58002 20.8289L5.60788 21.1615C4.51869 21.3452 3.48681 20.6112 3.30309 19.522L2.30518 13.6056"
          stroke="#172A4F"
          strokeWidth="2"
        />
        <path d="M9.55224 20.4962L8.22168 12.6077" stroke="#172A4F" strokeWidth="2" />
      </svg>
    </>
  );
};

export default NoRecipesIcon;
