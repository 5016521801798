import { ProductData } from 'lib/bigcommerce/types';
import ImageCard from './ImageCard';
import ProductCardTitle from './ProductCardTitle';

interface CarouselProductCardProps {
  product: ProductData;
}

const CarouselProductCard: React.FC<CarouselProductCardProps> = (props) => {
  const { product } = props;

  return (
    <>
      <ImageCard
        href={`${product.link}`}
        image={product.defaultImage}
        type={'carousel'}
        badge={product.subscriptionEnabled ? `subscription` : ``}
        isInStock={product.isInStock}
      >
        <ProductCardTitle product={product} />
      </ImageCard>
    </>
  );
};

export default CarouselProductCard;
