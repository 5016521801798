import React from 'react';
import Container from 'components/layout/Container';
import Link from 'components/Link';
import CarouselSwiper from 'components/carousel/CarouselSwiper';
import CarouselCategoryCard from 'components/card/CarouselCategoryCard';
import { ProductCategoryData } from 'lib/bigcommerce/types';
import { LinkData } from 'lib/contentful/types';

interface ProductCategoryCarouselProps {
  heading: string;
  subHeading: string;
  categories: ProductCategoryData[];
  callToActionLink: LinkData;
}

const ProductCategoryCarousel: React.FC<ProductCategoryCarouselProps> = (props) => {
  const { subHeading, heading, categories, callToActionLink } = props;

  return (
    <div className="relative w-full overflow-hidden text-sf-blue-700">
      <Container>
        <div className="relative w-full py-10 md:py-20">
          <div className="relative w-full pb-4">
            <div className="pb-2">
              <div className="relative flex w-full items-center justify-between">
                <div>{heading && <h4 className="text-3xl uppercase">{heading}</h4>}</div>
                <div>
                  {callToActionLink && (
                    <Link {...callToActionLink} className="text-base font-medium uppercase" />
                  )}
                </div>
              </div>
            </div>
            <div>{subHeading && <p className="text-base">{subHeading}</p>}</div>
          </div>
          <CarouselSwiper length={categories.length}>
            {categories.map((category) => (
              <CarouselCategoryCard key={category.entityId} category={category} />
            ))}
          </CarouselSwiper>
        </div>
      </Container>
    </div>
  );
};

export default ProductCategoryCarousel;
