import FilterOffIcon from 'components/icons/FilterOffIcon';
import FilterOnIcon from 'components/icons/FilterOnIcon';
import gsap from 'gsap';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { getSlugByText } from 'utils/string';

interface FilterItemProps {
  groupId: string;
  name: string;
}

const FilterItem: React.FC<FilterItemProps> = (props) => {
  const { name, groupId } = props;

  const iconOnRef = useRef(null);

  const router = useRouter();
  const routerParam: string = groupId;

  const handleFilter = useCallback(
    (filterName: string) => {
      if (filterName === 'All') {
        delete router.query[routerParam];
      } else {
        router.query[routerParam] = filterName;
      }
      router.push(router, null, { shallow: true });
    },
    [router, routerParam]
  );

  useEffect(() => {
    const iconOn = iconOnRef.current;

    if (name === router.query[routerParam] || (name == 'All' && !router.query[routerParam])) {
      gsap.to(iconOn, { opacity: 1, scale: 1, duration: 0.3, ease: 'power3.out' });
    } else {
      gsap.to(iconOn, { opacity: 0, scale: 0, duration: 0.3, ease: 'power3.out' });
    }

    return () => {
      gsap.killTweensOf(iconOn);
    };
  }, [router.query, name, routerParam]);

  return (
    <div>
      <button className={'text-sm'} onClick={() => handleFilter(name)}>
        <div className="relative flex flex-nowrap items-start justify-start gap-x-2 text-left">
          <div className="relative">
            <div className="relative">
              <div className="relative">
                <FilterOffIcon />
              </div>
              <div ref={iconOnRef} className="gpu absolute inset-0 scale-0 transform opacity-0">
                <FilterOnIcon />
              </div>
            </div>
          </div>
          <div>{name}</div>
        </div>
      </button>
    </div>
  );
};

export default FilterItem;
