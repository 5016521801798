import HorizontalLine from 'components/ui/HorizontalLine';
import Container from 'components/layout/Container';
import classnames from 'utils/classnames';

type HeadingProps = {
  heading?: string;
  subHeading?: string;
  divider?: boolean;
};

const Heading: React.FC<HeadingProps> = (props) => {
  const { heading, subHeading, divider } = props;
  return (
    <div className="relative w-full">
      <Container>
        <div className="relative w-full text-center text-sf-blue-700">
          <div className={classnames('relative w-full pb-7 pt-14 md:pb-14')}>
            <div>
              <h1 className="mx-auto max-w-[1026px] pb-7 text-3xl uppercase md:pb-9 md:text-5xl">
                {heading}
              </h1>
              <h2 className="mx-auto max-w-[600px] text-sm">{subHeading}</h2>
            </div>
          </div>
          {divider && (
            <div className="pb-4">
              <HorizontalLine />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
export default Heading;
