import ProductCard from 'components/card/ProductCard';
import { FilterType } from 'components/filters/filters.types';
import ModalFilter from 'components/filters/ModalFilter';
import SideFilter from 'components/filters/SideFilter';
import Container from 'components/layout/Container';
import BackToTopContainer from 'components/scroll/BackToTopContainer';
import { ProductStyle } from 'lib/bigcommerce/product-custom-fields';
import { PurchaseType } from 'lib/bigcommerce/product-options';
import { ProductData } from 'lib/bigcommerce/types';
import { BlockProductListData } from 'lib/contentful/types';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import NoProductsIcon from 'components/icons/NoProductsIcon';
import track from 'lib/tracking';

type BlockProductListProps = Omit<BlockProductListData, '__typename'> & {
  __typename: string;
};

const BlockProductList: React.FC<BlockProductListProps> = (props) => {
  const { products, categories } = props;
  const router = useRouter();

  const [filteredProducts, setFilteredProducts] = useState<Array<ProductData>>(products);

  const filters: FilterType[] = [
    {
      name: 'Category',
      id: 'category',
      filters: categories.map((x) => {
        return {
          id: x.entityId,
          name: x.name,
        };
      }),
    },
    {
      name: 'Purchase type',
      id: 'purchaseType',
      filters: Object.values(PurchaseType)
        .filter((y) => y != PurchaseType.OneOff)
        .map((x) => {
          return {
            id: x.label,
            name: x.label,
          };
        }),
    },
  ];

  useEffect(() => {
    const { category, productStyle, purchaseType } = router.query;

    const subscriptionEnabledFilter = !purchaseType
      ? null
      : purchaseType === PurchaseType.Subscription.label;

    const filterResult =
      category || productStyle || subscriptionEnabledFilter !== null
        ? products
            .filter((c) => (category ? c.categories.find((cat) => cat.name === category) : true))
            .filter((c) => (productStyle ? c.style == productStyle : true))
            .filter((c) =>
              subscriptionEnabledFilter !== null
                ? c.subscriptionEnabled === subscriptionEnabledFilter
                : true
            )
        : products;

    const filterId = [
      'product_list',
      props.sys.id,
      category,
      productStyle,
      subscriptionEnabledFilter,
    ].filter(Boolean);

    setFilteredProducts(filterResult);
    track.viewList(
      filterId.join('_'),
      'product_list_' + (category || 'all'),
      filterResult.map((p) => {
        return { item_id: p.sku, item_name: p.name };
      })
    );
  }, [router.query, products]);

  const trackProductViewed = (product) => {
    const { category } = router.query;
    track.selectItemFromList([
      {
        item_category: category?.toString() || 'all',
        item_id: product.sku,
        item_name: product.name,
      },
    ]);
  };

  return (
    <>
      <BackToTopContainer showBackToTop={filteredProducts.length > 3}>
        <div className="relative w-full">
          <ModalFilter filters={filters} />
          <Container>
            <>
              <div className="relative pt-6">
                <div className="relative w-full">
                  <div className="relative flex w-full flex-row flex-nowrap text-sf-blue-700">
                    <div className="relative">
                      <div className="hidden md:block">
                        <SideFilter filters={filters} />
                      </div>
                    </div>
                    <div className="relative w-full">
                      <div className="relative w-full">
                        {filteredProducts.length > 0 && (
                          <>
                            <div className="grid grid-cols-1 gap-x-6 gap-y-6 lg:grid-cols-2 lg:gap-y-10 xl:grid-cols-3">
                              {filteredProducts.map((product, index) => (
                                <ProductCard
                                  key={product.entityId}
                                  product={product}
                                  index={index}
                                  timestamp={Date.now()}
                                  trackClick={() => trackProductViewed(product)}
                                ></ProductCard>
                              ))}
                            </div>
                          </>
                        )}
                        {filteredProducts.length <= 0 && (
                          <div className="mt-20 flex flex-col items-center justify-center">
                            <NoProductsIcon />
                            <p className="pt-4 text-base font-normal text-sf-blue-700">
                              Sorry, there are no products to show
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Container>
        </div>
      </BackToTopContainer>
    </>
  );
};

export default BlockProductList;
