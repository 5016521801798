interface CrossIconProps {
  className?: string;
}

const CrossIcon: React.FC<CrossIconProps> = (props) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path d="M8.99997 1.00003L1 9M8.99997 8.99997L1 1" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default CrossIcon;
