interface FilterIconProps {
  className?: string;
}

const FilterIcon: React.FC<FilterIconProps> = (props) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M21 13L24 13" stroke="currentColor" strokeWidth="2" />
        <path d="M8 13H17" stroke="currentColor" strokeWidth="2" />
        <path d="M8 19H11" stroke="currentColor" strokeWidth="2" />
        <path d="M15 19L24 19" stroke="currentColor" strokeWidth="2" />
        <circle cx="19" cy="13" r="2" stroke="currentColor" strokeWidth="2" />
        <circle cx="13" cy="19" r="2" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default FilterIcon;
