import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import MenuIcon from 'components/icons/MenuIcon';
import CloseMenuIcon from 'components/icons/CloseMenuIcon';
import SanfordAndSonsIcon from 'components/icons/SanfordAndSonsIcon';
import NextLink from 'next/link';
import { FilterType } from './filters.types';
import FilterGroup from './FilterGroup';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import { useRouter } from 'next/router';
import { getSlugByText } from 'utils/string';
import { isTouch } from 'utils/device';

interface ModalFilterListProps {
  filters: FilterType[];
  onClose: () => void;
}

const ModalFilterList: React.FC<ModalFilterListProps> = (props) => {
  const { filters, onClose } = props;

  const router = useRouter();

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const buttonCloseRef = useRef(null);
  const buttonClearAllRef = useRef(null);
  const buttonSubmitResultsRef = useRef(null);

  useEffect(() => {
    const body = document.body;
    const container = containerRef.current;
    const content = contentRef.current;

    const buttonClose = buttonCloseRef.current;
    const buttonClearAll = buttonClearAllRef.current;
    const buttonSubmitResults = buttonSubmitResultsRef.current;

    gsap.set(container, { display: 'block', pointerEvents: 'auto' });

    const delay = 0;

    gsap.fromTo(
      container,
      { opacity: 1, y: '100%' },
      { y: '0%', opacity: 1, duration: 0.6, delay: delay, ease: 'power3.inOut' }
    );

    gsap.fromTo(
      content,
      { opacity: 0 },
      { opacity: 1, duration: 0.6, delay: delay + 0.3, ease: 'power3.inOut' }
    );

    gsap.set(body, { overflowY: 'hidden' });

    const closeMenu = () => {
      buttonClose.removeEventListener('click', closeMenu);

      gsap.fromTo(
        content,
        { opacity: 1 },
        { opacity: 0, duration: 0.6, delay: delay, ease: 'power3.out' }
      );

      gsap.to(container, {
        y: '100%',
        duration: 0.6,
        delay: delay + 0.3,
        ease: 'power2.in',
        onComplete: () => {
          onClose();
        },
      });
    };

    const clearAll = () => {
      gsap.killTweensOf(window);
      gsap.to(window, {
        duration: 0.6,
        ease: 'power3.inOut',
        scrollTo: { y: '.back-to-top-container', offsetY: 100, autoKill: !isTouch() },
      });

      filters.map((filter) => {
        delete router.query[filter.id];
      });

      router.push(router, null, { shallow: true });

      closeMenu();
    };

    const submitResults = () => {
      closeMenu();
    };

    buttonClose.addEventListener('click', closeMenu);
    buttonClearAll.addEventListener('click', clearAll);
    buttonSubmitResults.addEventListener('click', submitResults);

    return () => {
      buttonClose.removeEventListener('click', closeMenu);
      buttonClearAll.removeEventListener('click', clearAll);
      buttonSubmitResults.removeEventListener('click', submitResults);
      gsap.set(body, { overflowY: 'auto' });
    };
  }, []);

  return (
    <>
      <div className="fixed inset-0 z-40 overflow-hidden lg:hidden">
        <div ref={containerRef} className="absolute inset-0 hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-white"></div>
          </div>
          <div ref={contentRef} className="absolute inset-0 flex flex-col overflow-hidden">
            <div className="relative flex h-full w-full flex-col">
              <div className="relative flex w-full items-center justify-between px-6 py-6 text-sf-blue-700 lg:px-6 lg:py-14">
                <div className="lg:hidden">
                  <div className="invisible">
                    <MenuIcon></MenuIcon>
                  </div>
                </div>
                <div className="flex items-center justify-center lg:justify-start">
                  <div className="w-full">
                    <NextLink href={'/'} passHref>
                      <a target={'_self'}>
                        <SanfordAndSonsIcon className="h-[14px] w-auto lg:h-[19px] xl:h-[26px]"></SanfordAndSonsIcon>
                      </a>
                    </NextLink>
                  </div>
                </div>
                <div className="flex items-center justify-center">
                  <button ref={buttonCloseRef} className="lg:hidden">
                    <CloseMenuIcon></CloseMenuIcon>
                  </button>
                </div>
              </div>
              <div className="relative flex flex-1 text-sf-blue-700">
                <div className="relative h-full w-full">
                  <div className="absolute inset-0 overflow-y-scroll">
                    <div
                      className="relative flex w-full flex-col gap-y-4 px-6 py-6
                    "
                    >
                      {filters.map((item: FilterType, index: number) => {
                        return (
                          <FilterGroup
                            key={index}
                            filter={item}
                            isLastGroup={index === filters.length - 1}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-filters-shadow relative w-full bg-white px-6 py-4">
                <div className="relative flex items-center justify-between">
                  <button ref={buttonClearAllRef} className="text-sm text-sf-blue-700">
                    Clear all
                  </button>
                  <button
                    ref={buttonSubmitResultsRef}
                    className="flex items-center justify-center gap-x-2 rounded-full bg-sf-blue-700 px-6 py-1.5 text-sm font-medium text-white"
                  >
                    <div>Show results</div>
                    <div>
                      <ArrowRightIcon />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalFilterList;
