import React from 'react';
import ReactMarkdown from 'react-markdown';

interface MarkdownProps {
  content: string;
}

const Markdown: React.FC<MarkdownProps> = (props) => {
  const { content } = props;
  return (
    <ReactMarkdown className="markdown" linkTarget={'_blank'}>
      {content}
    </ReactMarkdown>
  );
};

export default Markdown;
