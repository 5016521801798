import React from 'react';
import { ProductCategoryData } from 'lib/bigcommerce/types';
import { categoryLink } from 'utils/links';
import CategoryCardTitle from './CategoryCardTitle';
import ImageCard from './ImageCard';

interface CarouselCategoryCardProps {
  category: ProductCategoryData;
}

const CarouselCategoryCard: React.FC<CarouselCategoryCardProps> = (props) => {
  const { category } = props;

  return (
    <>
      <ImageCard
        sizes="(max-width:767px) 90vw, 600px"
        href={categoryLink(category.name)}
        image={category.image}
        type={'carousel'}
        isInStock={category.productCount > 0}
      >
        <CategoryCardTitle>{category.name}</CategoryCardTitle>
      </ImageCard>
    </>
  );
};

export default CarouselCategoryCard;
