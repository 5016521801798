import React from 'react';

interface QuestionHeadlineProps {
  index: number;
  children?: React.ReactNode;
}

const QuestionHeadline: React.FC<QuestionHeadlineProps> = (props) => {
  const { children } = props;

  return (
    <div className="border-b-1 border-sf-blue-700 pb-6 text-3xl font-normal uppercase leading-7">
      {children}
    </div>
  );
};

export default QuestionHeadline;
