import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { OutlineLinkButton } from 'components/Button';
import Img from 'components/Img';
import Container from 'components/layout/Container';
import { BlockOffersCarouselData } from 'lib/contentful/types';
import { isDraft } from 'utils/contentful';
import React from 'react';

type BlockOffersCarouselProps = Omit<BlockOffersCarouselData, '__typename'> & {
  __typename: string;
};

const BlockOffersCarousel: React.FC<BlockOffersCarouselProps> = (props) => {
  const { offersCollection } = props;

  return (
    <div className="relative z-0 w-full py-10 md:py-12">
      <Swiper
        pagination={{
          clickable: true,
          bulletClass:
            'bg-sf-blue-700 bg-opacity-30 h-2 w-2 inline-block mx-1.5 rounded-full cursor-pointer',
          bulletActiveClass: 'bg-opacity-100',
        }}
        modules={[Pagination]}
        spaceBetween={0}
        slidesPerView={1}
      >
        {offersCollection.items
          .filter((item) => !isDraft(item))
          .map((offer) => (
            <SwiperSlide key={offer?.sys?.id} className="">
              <div className="relative">
                <div className="relative w-full">
                  <div className="absolute inset-0">
                    <div className="absolute inset-0 bg-sf-blue-300 opacity-20"></div>
                    <div className="absolute inset-0">
                      <Img {...offer.image} layout="fill" objectFit="cover" />
                    </div>
                  </div>
                  <Container className="flex h-full min-h-[600px] items-end justify-center md:min-h-0">
                    <div className="py-7 text-center text-white sm:text-left md:py-12">
                      <div className="sm:w-7/12 md:w-6/12 lg:w-5/12">
                        <div className="rounded bg-sf-blue-700 sm:aspect-h-1  sm:aspect-w-1">
                          <div className="flex px-6 sm:px-12">
                            <div className="flex w-80 flex-col items-start justify-center py-12 md:py-20">
                              {offer.heading && (
                                <h1 className="text-left text-5xl uppercase">{offer.heading}</h1>
                              )}
                              {offer.description && (
                                <p className="mt-6 text-left text-sm">{offer.description}</p>
                              )}
                              {offer.callToActionButton && (
                                <div className="mt-6">
                                  <OutlineLinkButton {...offer.callToActionButton} color="white" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
              <div className="h-12" />
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="space-y-20" />
    </div>
  );
};

export default BlockOffersCarousel;
