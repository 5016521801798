/* eslint-disable jsx-a11y/alt-text */

import Img from 'components/Img';
import Container from 'components/layout/Container';
import { BlockTestimonialData } from 'lib/contentful/types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import classnames from 'utils/classnames';

type BlockTestimonialProps = Omit<BlockTestimonialData, '__typename'> & {
  __typename: string;
};

const BlockTestimonial: React.FC<BlockTestimonialProps> = (props) => {
  const { image, heading, testimonialsCollection } = props;
  //@danilo if badge is true should import the static icon (this won't come from the cms)

  return (
    <div>
      <div className="relative w-full bg-sf-gold-100 text-sf-blue-700">
        <div className="relative flex items-center sm:h-[720px]">
          <div className="left-1/2 hidden h-full w-1/2 sm:absolute sm:block">
            <Img
              {...image}
              priority={true}
              layout={'fill'}
              objectFit="cover"
              className="invisibile sm:visible"
            />
          </div>
          <Container className="px-0">
            <div className="px-6 py-16 sm:w-1/2 md:px-24">
              <div className="text-center">
                <h1 className="mb-5 text-base font-medium md:mb-12 md:text-lg">{heading}</h1>
                <Swiper
                  pagination={{
                    clickable: true,
                    bulletClass:
                      'bg-sf-blue-700 bg-opacity-30 h-2 w-2 inline-block mx-1.5 rounded-full cursor-pointer',
                    bulletActiveClass: 'bg-opacity-100',
                  }}
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  className=""
                >
                  {testimonialsCollection.items.map((item) => (
                    <SwiperSlide key={item.sys.id} className="self-center">
                      <div className="h-full w-full">
                        <h1 className="mb-6 text-2xl font-medium sm:mb-12 lg:text-4xl">
                          &ldquo;{item.review}&rdquo;
                        </h1>
                        {item.name && (
                          <div
                            className={classnames('text-sm font-medium', {
                              'mb-14 md:mb-20': testimonialsCollection.items.length > 1,
                            })}
                          >
                            {item.name}
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </Container>
        </div>
        <div className="sm:hidden">
          <Img {...image} priority={true} layout="responsive" />
        </div>
      </div>
    </div>
  );
};

export default BlockTestimonial;
