import { BlockProductCarouselData } from 'lib/contentful/types';
import ProductCarousel from 'components/carousel/ProductCarousel';

type BlockProductCarouselProps = Omit<BlockProductCarouselData, '__typename'> & {
  __typename: string;
};

const BlockProductCarousel: React.FC<BlockProductCarouselProps> = (props) => {
  const { heading, cta, products } = props;

  return (
    <ProductCarousel
      heading={heading}
      cta={cta}
      products={products}
      trackingId={props.sys.id}
      trackingUnique={props.sys.id}
      trackingName="product carousel"
    ></ProductCarousel>
  );
};

export default BlockProductCarousel;
