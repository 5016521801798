/* eslint-disable jsx-a11y/alt-text */

import { OutlineLinkButton } from 'components/Button';
import Container from 'components/layout/Container';
import { BlockProcessData } from 'lib/contentful/types';
import classnames from 'utils/classnames';

type BlockProcessProps = Omit<BlockProcessData, '__typename'> & {
  __typename: string;
};

const BlockProcess: React.FC<BlockProcessProps> = (props) => {
  const { processesCollection, heading, callToActionButton } = props;

  return (
    <div>
      <div className="relative w-full py-8 pb-10 text-sf-blue-700 md:py-12">
        <Container>
          {heading ? <h1 className="mb-10 text-3xl uppercase sm:mb-0">{heading}</h1> : null}
          <div className="mx-auto mb-8 grid grid-cols-4 items-center gap-x-8 gap-y-16 sm:mb-12 md:max-w-[550px] lg:max-w-none lg:grid-cols-12">
            {processesCollection.items.map((process, index) => (
              <div
                className={classnames('relative col-span-4 flex sm:col-span-2 lg:col-span-3', {
                  'mr-auto sm:mr-0 sm:mt-16': index === 0,
                  'ml-auto sm:ml-0 sm:mt-24': index === 1,
                  'mr-auto sm:mr-0': index === 2,
                  'ml-auto sm:ml-0 sm:mt-16': index === 3,
                })}
                key={process.sys.id}
              >
                <div className="group relative mx-auto flex items-center duration-1000 hover:scale-105 hover:ease-out">
                  <img
                    className="duration-1000 group-hover:-translate-y-1 group-hover:scale-105"
                    src="/assets/images/general/process-background.png"
                  />
                  <div className="absolute w-full p-6 px-8 text-center">
                    <img
                      className="mx-auto w-40 duration-1000 group-hover:scale-105 lg:w-36 xl:w-40"
                      src={process.icon.url}
                    />{' '}
                    {/**SVG  */}
                    <div className="duration-1000 group-hover:translate-y-1">
                      <span className="text-3xl text-sf-blue-500">
                        {(index + 1).toString().padStart(2, '0')}.
                      </span>
                      <div className="pb-6 leading-tight text-sf-blue-700">
                        {process.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {callToActionButton && (
            <div className="text-center uppercase">
              <OutlineLinkButton {...callToActionButton}></OutlineLinkButton>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default BlockProcess;
