import React from 'react';

const CategoryCardTitle: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <div className="px-6 py-4">
      <span className="text-lg font-medium uppercase">{props.children}</span>
    </div>
  );
};

export default CategoryCardTitle;
