import { FillLinkButton } from 'components/Button';
import { BlockCopyCtaData } from 'lib/contentful/types';
import Container from 'components/layout/Container';

type BlockCopyCtaProps = Omit<BlockCopyCtaData, '__typename'> & {
  __typename: string;
};

const BlockCopyCta: React.FC<BlockCopyCtaProps> = (props) => {
  const { heading, copy, callToActionButton } = props;

  return (
    <div className="mb-28">
      <Container>
        <div className="rounded bg-sf-blue-500/5 py-8 sm:py-24">
          <div className="mx-auto w-10/12 text-center text-sf-blue-700">
            {heading && <h3 className="mb-8 text-2xl font-medium sm:text-3xl">{heading}</h3>}
            {copy && <div className="mb-6 text-lg sm:mb-12 sm:text-2xl">{copy}</div>}
            {callToActionButton && (
              <FillLinkButton {...callToActionButton} className="uppercase"></FillLinkButton>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BlockCopyCta;
