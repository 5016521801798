import React, { useState } from 'react';
import CrossIcon from 'components/icons/CrossIcon';
import PlusIcon from 'components/icons/PlusIcon';
import Markdown from '../markdown/Markdown';

interface QuestionProps {
  question: string;
  answer: string;
}

const Question: React.FC<QuestionProps> = (props) => {
  const [isQuestionOpen, setIsQuestionOpen] = useState(false);

  const { question, answer } = props;

  return (
    <div className="relative w-full">
      <div className="h-[2px] bg-sf-blue-700 opacity-5" />
      <div
        className="flex cursor-pointer items-center justify-between py-6 text-base font-normal"
        onClick={() => {
          if (!isQuestionOpen) {
            setIsQuestionOpen(true);
          } else {
            setIsQuestionOpen(false);
          }
        }}
      >
        <div>
          <div className="pr-3 font-medium leading-tight">{question}</div>
        </div>
        <div>{isQuestionOpen ? <CrossIcon /> : <PlusIcon />}</div>
      </div>
      {isQuestionOpen ? <Markdown content={answer} /> : null}
    </div>
  );
};

export default Question;
