import UnitArrowDownIcon from 'components/icons/UnitArrowDownIcon';
import UnitArrowUpIcon from 'components/icons/UnitArrowUpIcon';
import QuestionLink from 'components/question/QuestionLink';
import { useState } from 'react';
import classnames from 'utils/classnames';

interface SelectFilterProps {
  items: any[];
  onChange: (currentIndex: number) => void;
}

const SelectFilter: React.FC<SelectFilterProps> = (props) => {
  const { items, onChange } = props;

  const [activeItem, setActiveItem] = useState(items[0]);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="pb-2">
        <div
          className="relative w-full"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className="relative flex w-full items-center justify-between">
            <div>
              <QuestionLink
                icon={activeItem.icon}
                title={activeItem.title}
                active={true}
                useBottomBar={false}
                onClick={() => {}}
              />
            </div>
            <div>
              {isOpen && (
                <>
                  <UnitArrowUpIcon />
                </>
              )}
              {!isOpen && (
                <>
                  <UnitArrowDownIcon />
                </>
              )}
            </div>
          </div>
          <div
            className={classnames('absolute bottom-0 left-0 right-0 h-[2px] w-full bg-sf-blue-700')}
          ></div>
        </div>
      </div>
      {isOpen && (
        <>
          <div>
            {items.map((collection, index) => {
              return (
                <div key={collection.sys?.id}>
                  <QuestionLink
                    icon={collection.icon}
                    title={collection.title}
                    useBottomBar={false}
                    active={false}
                    onClick={() => {
                      // questionLinkHandler(index);
                      setIsOpen(false);
                      setActiveItem(collection);
                      onChange(index);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
export default SelectFilter;
