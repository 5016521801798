interface NoProductsIconProps {
  className?: string;
}

const NoProductsIcon: React.FC<NoProductsIconProps> = (props) => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M37 19.0167L24 24.8668L11 19.0167V33.4096L24 39.1667M37 19.0167L24 13.1667L15.9833 16.7742M37 19.0167V33.4096L24 39.1667M24 24.7739V39.1667"
          stroke="#172A4F"
          strokeWidth="2"
        />
        <path
          d="M24 13.1667L37 19.0167V14.9L24 8.83337V13.1667Z"
          stroke="#172A4F"
          strokeWidth="2"
        />
        <path
          d="M11 19.0167L24 24.8667V20.5333L11 14.4667V19.0167Z"
          stroke="#172A4F"
          strokeWidth="2"
        />
      </svg>
    </>
  );
};

export default NoProductsIcon;
