import { FilterType } from './filters.types';
import SideFilterList from './SideFilterList';

interface SideFilterProps {
  filters: FilterType[];
}

const SideFilter: React.FC<SideFilterProps> = (props) => {
  const { filters } = props;

  return (
    <div className="relative md:mr-6 md:min-w-[224px]">
      <SideFilterList filters={filters} />
    </div>
  );
};

export default SideFilter;
