import ArrowUpIcon from 'components/icons/ArrowUpIcon';
import Container from 'components/layout/Container';
import gsap from 'gsap';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import classnames from 'utils/classnames';
import { isTouch } from 'utils/device';

interface BackToTopContainerProps {
  classNames?: string;
  showBackToTop?: boolean;
  children?: React.ReactNode;
}

const BackToTopContainer: React.FC<BackToTopContainerProps> = (props) => {
  const { showBackToTop, children } = props;

  const router = useRouter();
  const containerRef = useRef(null);

  const onBackToTop = () => {
    gsap.killTweensOf(window);
    gsap.to(window, {
      duration: 0.6,
      ease: 'power3.inOut',
      scrollTo: { y: '.back-to-top-container', offsetY: 100, autoKill: !isTouch() },
    });
  };

  useEffect(() => {
    const keys = Object.keys(router.query);

    let keyCounter = 0;
    keys.forEach(() => {
      keyCounter = keyCounter + 1;
    });

    if (keyCounter > 1) {
      onBackToTop();
    }

    return () => {};
  }, [router.query]);

  return (
    <>
      <div ref={containerRef} className="back-to-top-container relative w-full">
        {children}
      </div>
      <Container>
        <div className="relative my-10 w-full py-10 text-center md:my-14 md:py-14">
          <button
            className={classnames(
              'rounded-full border border-sf-blue-700 px-6 py-2.5 text-base font-medium uppercase text-sf-blue-700',
              {
                hidden: !showBackToTop,
              }
            )}
            onClick={onBackToTop}
          >
            <div className="flex items-center justify-center">
              <div>Back to the top</div>
              <div className="px-2">
                <ArrowUpIcon />
              </div>
            </div>
          </button>
        </div>
      </Container>
    </>
  );
};

export default BackToTopContainer;
