interface ArrowUpIconProps {
  className?: string;
}

const ArrowUpIcon: React.FC<ArrowUpIconProps> = (props) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M16 28L16 4" stroke="currentColor" strokeWidth="2" />
        <path d="M10 10L16 4L22 10" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default ArrowUpIcon;
