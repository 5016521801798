/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Container from 'components/layout/Container';

import { BlockRichTextData } from 'lib/contentful/types';
import React from 'react';

type BlockRichTextProps = Omit<BlockRichTextData, '__typename'> & {
  __typename: string;
};

const options: Options = {
  renderText: (text: string) => {
    return text.split('\n').map((t, i) =>
      i > 0 ? (
        <React.Fragment key={i}>
          <br />
          {t}
        </React.Fragment>
      ) : (
        <React.Fragment key={i}>{t}</React.Fragment>
      )
    );
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node: any, children: React.ReactNode) => {
      if (documentToPlainTextString(node).trim() === '') return null;
      return <h2>{children}</h2>;
    },
    [BLOCKS.HEADING_3]: (node: any, children: React.ReactNode) => {
      if (documentToPlainTextString(node).trim() === '') return null;
      return <h3>{children}</h3>;
    },
    [BLOCKS.HEADING_4]: (node: any, children: React.ReactNode) => {
      if (documentToPlainTextString(node).trim() === '') return null;
      return <h4>{children}</h4>;
    },
    [BLOCKS.HEADING_5]: (node: any, children: React.ReactNode) => {
      if (documentToPlainTextString(node).trim() === '') return null;
      return <h5>{children}</h5>;
    },

    [BLOCKS.OL_LIST]: (node: any, children: React.ReactNode) => {
      if (documentToPlainTextString(node).trim() === '' && !children) return null;
      if (documentToPlainTextString(node).trim() === '') return children;
      return <ol>{children}</ol>;
    },
    [BLOCKS.UL_LIST]: (node: any, children: React.ReactNode) => {
      if (documentToPlainTextString(node).trim() === '' && !children) return null;
      if (documentToPlainTextString(node).trim() === '') return children;
      return <ul>{children}</ul>;
    },
    [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => {
      const doc = documentToPlainTextString(node).trim();

      if (doc === '') return <p>{children}</p>;
      if (!children) return;

      return <p>{children}</p>;
    },
  },
};

const BlockRichText: React.FC<BlockRichTextProps> = (props) => {
  const { content } = props;

  return (
    <div className="pb-12 pt-6">
      <Container>
        <div className="prose prose-sm prose-sf-blue relative mx-auto max-w-2xl sm:prose-base">
          {/** Every element of the rich text should be handled by the options objec above.
           * We can style every single element individually or it's easier create a wrapper in Scss
           * having something like this, but I'd like to keep it simpler and using the options/switch case statement.
           *
           * .richtext {
           *  p {
           * }
           * a {
           * }
           * hr {
           * }
           */}
          {documentToReactComponents(content?.json, options)}
        </div>
      </Container>
    </div>
  );
};

export default BlockRichText;
