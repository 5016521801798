import { FilterType } from './filters.types';
import FilterGroup from './FilterGroup';

interface SideFilterListProps {
  filters: FilterType[];
}

const SideFilterList: React.FC<SideFilterListProps> = (props) => {
  const { filters } = props;

  return (
    <div className="relative flex w-full flex-col gap-y-6 pb-10">
      {filters.map((item: FilterType, index: number) => {
        return <FilterGroup key={index} filter={item} isLastGroup={index === filters.length - 1} />;
      })}
    </div>
  );
};

export default SideFilterList;
