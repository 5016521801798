interface PlusIconProps {
  className?: string;
}

const PlusIcon: React.FC<PlusIconProps> = (props) => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path
          d="M11.6569 6.0006H6.00012M6.00012 6.0006H0.343262M6.00012 6.0006L6.00012 11.6574M6.00012 6.0006V0.34375"
          stroke="currentColor"
          strokeWidth="2"
        />
      </svg>
    </>
  );
};

export default PlusIcon;
