import gsap from 'gsap';
import { ProductData } from 'lib/bigcommerce/types';
import { useEffect, useRef } from 'react';
import { productLink } from 'utils/links';
import ImageCard from './ImageCard';
import ProductCardTitle from './ProductCardTitle';

interface ProductCardProps {
  product: ProductData;
  index: number;
  timestamp: number;
  trackClick?: () => void;
}

const ProductCard: React.FC<ProductCardProps> = (props) => {
  const { product, index, timestamp, trackClick } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    gsap.fromTo(
      container,
      { opacity: 0, scale: 1, y: 30, rotationY: 90, transformOrigin: 'center center' },
      {
        opacity: 1,
        scale: 1,
        y: 0,
        rotationY: 0,
        duration: 1,
        delay: index / 10,
        ease: 'power3.out',
      }
    );

    return () => {
      gsap.killTweensOf(container);
    };
  }, [index, timestamp]);

  return (
    <>
      <div
        className="gpu perspective flex"
        onMouseDown={() => {
          trackClick();
        }}
      >
        <div ref={containerRef} className="relative flex h-full w-full opacity-0">
          <ImageCard
            href={product.link}
            image={product.defaultImage}
            type={'listing'}
            badge={product.subscriptionEnabled ? `subscription` : ``}
            isInStock={product.isInStock}
          >
            <ProductCardTitle product={product}></ProductCardTitle>
          </ImageCard>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
