import { ProductData } from 'lib/bigcommerce/types';
import { numberToCurrency } from 'utils/currency';

interface ProductCardTitleProps {
  product: ProductData;
}

const ProductCardTitle: React.FC<ProductCardTitleProps> = (props) => {
  const { product } = props;

  const isProductOnSale: boolean = product.salePrice !== null;

  return (
    <div className="flex h-auto w-full flex-col justify-between px-6 py-4">
      <div>
        <div className="pb-8">
          <span className="text-lg font-medium">{product.name}</span>
        </div>
      </div>
      <div>
        <div>
          <span className="translate-z-100 text-xs">{product.weightSummary}</span>
        </div>
        <div>
          <span className="text-lg">{`${numberToCurrency(
            isProductOnSale ? product.salePrice : product.price,
            product.currency
          )}`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProductCardTitle;
