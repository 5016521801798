import FilterIcon from 'components/icons/FilterIcon';
import Container from 'components/layout/Container';
import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { FilterType } from './filters.types';
import ModalFilterList from './ModalFilterList';

interface ModalFilterProps {
  filters: FilterType[];
}

const ModalFilter: React.FC<ModalFilterProps> = (props) => {
  const { filters } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const onModalClose = () => {
    setModalOpen(false);
  };

  const containerAnimationTriggerRef = useRef(null);
  const filterButtonRef = useRef(null);
  const backgroundFilterShadowRef = useRef(null);
  const backgroundFilterButtonRef = useRef(null);
  const lineFilterRef = useRef(null);

  useEffect(() => {
    const container = containerAnimationTriggerRef.current;
    const filterButon = filterButtonRef.current;
    const backgroundFilterShadow = backgroundFilterShadowRef.current;
    const backgroundFilterButton = backgroundFilterButtonRef.current;
    const lineFilter = lineFilterRef.current;

    gsap.fromTo(
      backgroundFilterShadow,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: container,
          start: 'top 80px',
          end: 'top top',
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      lineFilter,
      {
        x: '-100%',
      },
      {
        x: '0%',
        scrollTrigger: {
          trigger: container,
          start: 'top 80px',
          end: 'top top',
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      filterButon,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: container,
          start: 'top 80px',
          end: 'top top',
          scrub: true,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      backgroundFilterButton,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: container,
          start: 'top 80px',
          end: 'top 75px',
          scrub: true,
          markers: false,
        },
      }
    );

    return () => {
      gsap.killTweensOf([
        backgroundFilterShadow,
        backgroundFilterButtonRef,
        lineFilter,
        filterButon,
      ]);
    };
  }, []);

  return (
    <>
      <div className="md:--hidden sticky top-[80px] z-10 md:hidden">
        <Container>
          <div className={'relative h-[2px] w-full overflow-hidden bg-white'}>
            <div ref={lineFilterRef} className="absolute inset-0 bg-sf-blue-700 opacity-5"></div>
          </div>
        </Container>
        <div className="relative max-h-[0px] w-full">
          <button
            className="gpu relative w-full text-sm text-sf-blue-700"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <div
              ref={backgroundFilterShadowRef}
              className="button-filters-shadow absolute inset-0 opacity-0"
            ></div>
            <div
              ref={backgroundFilterButtonRef}
              className="absolute inset-0 bg-white opacity-0"
            ></div>
            <div
              ref={filterButtonRef}
              className="relative flex items-center justify-center py-2 opacity-0"
            >
              <div>Filter</div>
              <div className="gpu">
                <FilterIcon />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div ref={containerAnimationTriggerRef} className="relative w-full"></div>
      {modalOpen && (
        <>
          <div className="fixed inset-0 z-30">
            <div className="pointer-events-auto absolute inset-0">
              <ModalFilterList filters={filters} onClose={onModalClose} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ModalFilter;
