/* eslint-disable jsx-a11y/alt-text */
import Img from 'components/Img';
import Container from 'components/layout/Container';
import Link from 'components/Link';
import Markdown from 'components/markdown/Markdown';
import { BlockFeaturedData } from 'lib/contentful/types';
import classnames from 'utils/classnames';

type BlockFeaturedProps = Omit<BlockFeaturedData, '__typename'> & {
  __typename: string;
};

const BlockFeatured: React.FC<BlockFeaturedProps> = (props) => {
  const { heading, description, link, image, contentCentered, imagePosition } = props;
  return (
    <div className="mb-8 lg:mb-28">
      <Container>
        <div className="grid grid-cols-4 gap-y-6 lg:grid-cols-12">
          <div
            className={classnames('col-span-4 lg:col-span-6', {
              'lg:order-last': imagePosition === 'RIGHT',
            })}
          >
            <div className="aspect-h-1 aspect-w-1 sm:aspect-h-3 sm:aspect-w-4 lg:aspect-h-1 lg:aspect-w-1">
              <Img {...image} className="rounded" layout="fill" objectFit="cover" />
            </div>
          </div>
          <div
            className={classnames(
              'col-span-4 p-6 pb-0 text-sf-blue-700 lg:col-span-6 lg:p-12 lg:pb-0',
              {
                'place-self-center text-center': contentCentered,
                'lg:pr-[88px]': imagePosition === 'RIGHT',
                'lg:pl-[88px]': imagePosition !== 'RIGHT',
              }
            )}
          >
            {heading && (
              <h2
                className={classnames('mb-5 uppercase xl:mb-8', {
                  'text-3xl lg:text-5xl': contentCentered,
                  'text-2xl lg:text-3xl': !contentCentered,
                })}
              >
                {heading}
              </h2>
            )}
            {description && (
              <div className="pb-6 pt-4 xl:pt-8">
                <Markdown content={description} />
              </div>
            )}
            {link && <Link {...link} className="text-lg underline underline-offset-4" />}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BlockFeatured;
