import HorizontalLine from 'components/ui/HorizontalLine';
import FilterItem from './FilterItem';
import { FilterType } from './filters.types';

interface FilterGroupProps {
  filter: FilterType;
  isLastGroup?: boolean;
}

const FilterGroup: React.FC<FilterGroupProps> = (props) => {
  const { isLastGroup } = props;
  const { name, filters, id } = props.filter;

  return (
    <div>
      <div>
        <span className="text-base font-medium uppercase">{name}</span>
      </div>
      <div className="relative flex flex-col gap-y-2 py-4">
        <FilterItem groupId={id} name={'All'} />
        {filters.map((filter) => (
          <FilterItem key={filter.id} groupId={id} name={filter.name} />
        ))}
      </div>
      {!isLastGroup && (
        <>
          <div>
            <HorizontalLine />
          </div>
        </>
      )}
    </div>
  );
};

export default FilterGroup;
