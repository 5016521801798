interface ArrowRightIconProps {
  className?: string;
}

const ArrowRightIcon: React.FC<ArrowRightIconProps> = (props) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
      >
        <path d="M4 16L28 16" stroke="currentColor" strokeWidth="2" />
        <path d="M22 10L28 16L22 22" stroke="currentColor" strokeWidth="2" />
      </svg>
    </>
  );
};

export default ArrowRightIcon;
