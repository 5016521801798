import * as Blocks from 'components/blocks';
import { AllBlocksData } from 'lib/contentful/types';

interface BlockLoaderProps {
  blocks: Array<AllBlocksData>;
}

const BlockLoader: React.FC<BlockLoaderProps> = (props) => {
  const { blocks } = props;

  return (
    <>
      {blocks.map((block, index) => {
        if (!block || !block?.sys?.id) {
          console.warn('Block Not Found', block);
          return null;
        }
        const BlockComponent = Blocks[block.__typename];

        return (
          <section key={`${block.sys.id}-${index}`} data-block={block.__typename}>
            <BlockComponent {...block} />
          </section>
        );
      })}
    </>
  );
};

export default BlockLoader;
