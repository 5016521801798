import React from 'react';
import { BlockProductCategoryCarouselData } from 'lib/contentful/types';
import ProductCategoryCarousel from 'components/carousel/ProductCategoryCarousel';

type BlockProductCategoryCarouselProps = Omit<BlockProductCategoryCarouselData, '__typename'> & {
  __typename: string;
};

const BlockProductCategoryCarousel: React.FC<BlockProductCategoryCarouselProps> = (props) => {
  const { heading, subHeading, categories, callToActionLink } = props;

  return (
    <>
      <ProductCategoryCarousel
        heading={heading}
        subHeading={subHeading}
        categories={categories}
        callToActionLink={callToActionLink}
      />
    </>
  );
};

export default BlockProductCategoryCarousel;
