import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { FilterType } from 'components/filters/filters.types';
import ModalFilter from 'components/filters/ModalFilter';
import SideFilter from 'components/filters/SideFilter';
import Container from 'components/layout/Container';
import BackToTopContainer from 'components/scroll/BackToTopContainer';
import RecipeCard from 'components/card/RecipeCard';
import { BlockRecipesListData, RecipeListingData } from 'lib/contentful/types';
import NoRecipesIcon from 'components/icons/NoRecipesIcon';

type BlockRecipestListProps = Omit<BlockRecipesListData, '__typename'> & {
  __typename: string;
};

const BlockRecipestList: React.FC<BlockRecipestListProps> = (props) => {
  const { recipes, cookingMethodFilters, seafoodTypeFilters } = props;
  const router = useRouter();

  const [filteredRecipes, setFilteredRecipes] = useState<Array<RecipeListingData>>(recipes);

  const filters: FilterType[] = [
    {
      name: 'Cooking Method',
      id: 'cookingMethod',
      filters: cookingMethodFilters,
    },
    {
      name: 'Seafood Type',
      id: 'seafoodType',
      filters: seafoodTypeFilters,
    },
  ];

  useEffect(() => {
    const { cookingMethod, seafoodType } = router.query;
    const filteredResults =
      cookingMethod || seafoodType
        ? recipes
            .filter((c) => (cookingMethod ? c.cookingMethod === cookingMethod : true))
            .filter((c) => (seafoodType ? c.seafoodType === seafoodType : true))
        : recipes;

    setFilteredRecipes(filteredResults);
  }, [router.query, recipes]);

  return (
    <>
      <BackToTopContainer showBackToTop={filteredRecipes.length > 3}>
        <div className="relative w-full">
          <ModalFilter filters={filters} />
          <Container>
            <>
              <div className="relative pt-6">
                <div className="relative w-full">
                  <div className="relative flex w-full flex-row flex-nowrap text-sf-blue-700">
                    <div className="relative">
                      <div className="hidden md:block">
                        <SideFilter filters={filters} />
                      </div>
                    </div>
                    <div className="relative w-full">
                      <div className="relative w-full">
                        {filteredRecipes.length > 0 && (
                          <>
                            <div className="grid grid-cols-1 gap-x-6 gap-y-6 lg:grid-cols-2 lg:gap-y-10 xl:grid-cols-3">
                              {filteredRecipes.map((recipe, index) => (
                                <RecipeCard
                                  key={recipe?.sys?.id}
                                  recipe={recipe}
                                  index={index}
                                  timestamp={Date.now()}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        {filteredRecipes.length <= 0 && (
                          <div className="mt-20 flex flex-col items-center justify-center">
                            <NoRecipesIcon />
                            <p className="pt-4 text-base font-normal text-sf-blue-700">
                              Sorry, there are no recipes to show
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Container>
        </div>
      </BackToTopContainer>
    </>
  );
};

export default BlockRecipestList;
