import BlockLoader from 'containers/BlockLoader';
import Layout from 'containers/Layout';
import contentful from 'lib/contentful';
import { PageDataQueryResponse } from 'lib/contentful/data/page';
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import { REVALIDATION_TIME } from '../lib/constants';

const Page: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({ data }) => {
  const { siteSettings, siteBanners, page, preview } = data;

  const { blocksCollection } = page;

  return (
    <Layout
      preview={preview}
      siteSettings={siteSettings}
      siteBanners={siteBanners}
      seo={{
        title: page.pageTitle,
        description: page.pageDescription,
        openGraphImageUrl: page.openGraphImage?.url,
      }}
    >
      <BlockLoader blocks={blocksCollection.items} />
    </Layout>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const pages = await contentful.pages.allPagesPath();
  const paths: Array<{ params: { url: string[] } }> = pages.map((x) => {
    return {
      params: {
        url: [x.url],
      },
    };
  });

  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps<{ data: PageDataQueryResponse }> = async ({
  params,
  preview,
}) => {
  const homePage = !!!params.url;
  const url = homePage ? undefined : `${(params.url as string[]).join('/')}`;

  const pageResponse = await contentful.pages.getPage({
    url,
    preview,
    homePage,
  });

  if (!pageResponse?.page) {
    return {
      notFound: true,
    };
  }

  return {
    revalidate: REVALIDATION_TIME.SHORT,
    props: {
      data: pageResponse,
    },
  };
};

export default Page;
